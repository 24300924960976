<template>
<div style="max-width:1200px; margin: 0 auto;">
    <v-switch v-if="requestDebug" :true-value="true" :false-value="false" v-model="ui.debug" label="Debug"/>
    <v-container v-if="ready"  :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
        <v-row v-if="debug">
            <v-col align="right">{{time_spent}}</v-col>
        </v-row>
        <v-row v-if="debug">
            <v-col align="left">
              <v-list dense>
                <v-list-item v-for="logic in logics" :key="`logic-report-${logic.id}`">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{logic.description}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Requirement: {{logic.requirement}}
                      <br>
                      Result: <span :style="{'color':logic.result ? 'green' : 'grey'}">{{logic.result}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <ul>
                      <li v-for="(report, index) in logic.report.rules" :key="`logic-helper-${logic.id}-${index}`" v-html="report.helper"/>
                    </ul>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
        </v-row>
      <v-row>
        <v-col :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
          <!-- <v-switch v-model="ui.debug" :true-value="true" :false-value="false" :label="'Debug'"/> -->

          <template v-if="debug">
            <v-btn text @click="cacheAnswers">cacheAnswers</v-btn> | <v-btn text @click="loadAnswers">loadAnswers</v-btn> | <v-btn text @click="randomize">Randomize</v-btn> | <v-btn text @click="reset">Reset</v-btn> | <v-btn text @click="ui.completionHelper=!ui.completionHelper">Toggle Validation Mode</v-btn>          

            <v-navigation-drawer
              v-model="ui.questionNav"
              width="200"
              fixed
            >
              <v-list>
                <v-list-item>
                  Question Navigation
                </v-list-item>
              </v-list>
              <v-divider/>
              
              <v-list>
                <v-list-item v-for="(question, questionIndex) in questions" :key="'debug_question_nav_'+question.id">
                  <v-btn @click="survey().scrollToQuestion(question)" :class="[{'hasLogic':question.canHide || question.canDisable},{'hidden':!question.display}]">
                    #:{{questionIndex+1}} ID:{{question.id}}
                  </v-btn>
                </v-list-item>
              </v-list>

            </v-navigation-drawer>
          </template>
          
          <div v-if="ui.completionHelper" style="z-index: 1000;" :style="{'position':'fixed', 'top': completionHelper.config.offsetTop, 'right': completionHelper.config.offsetRight}">
            <v-btn small text @click="ui.completionHelper=false">[X]</v-btn>
             <str index="questionSet.completion_helper.label"/>:
            <div class="completion-helper" v-for="(question, index) in invalidQuestions" :key="'completion_helper_'+question.id" @mouseenter="survey().highlightQuestionON(question)"  @mouseleave="survey().highlightQuestionOFF(question)" @click="survey().scrollToQuestion(question)">
              <div style="padding-top: 50%;transform: translate(0, -20%);">{{index+1}}</div>
            </div>
          </div>



          <template v-for="(question, questionIndex) in questions">
            <div v-show="question.display" :key="question.id" :data-question-position="questionIndex" class="question-container" :class="[{'question-container-incomplete': ui.completionHelper && question.display===true && question.valid===false},{'visible':question.display},{'hidden':!question.display}]">
              <!-- <template v-for="promptRef in question.prompts">
                  <Prompt v-if="promptMap[promptRef.prompt_id] && promptRef.position=='top'" :key="'question_'+question.id+'_prompt_'+promptRef.prompt_id" :debug="debug" :prompt="promptMap[promptRef.prompt_id]" :assets="assets" :references="references" v-show="promptMap[promptRef.prompt_id].display || debug" class="prompt" :class="[{'hidden':!promptMap[promptRef.prompt_id].display}]"/>
              </template>           -->
              <Question v-model="questions[questionIndex].form" :triggers="ui.triggers[question.id]" :debug="debug" :question="question" @questionUpdate="displayHandler" v-show="question.display || debug"/>
              <!-- <template v-for="promptRef in question.prompts">
                  <Prompt v-if="promptMap[promptRef.prompt_id] && promptRef.position=='bottom'" :key="'question_'+question.id+'_prompt_'+promptRef.prompt_id" :debug="debug" :prompt="promptMap[promptRef.prompt_id]" :assets="assets" :references="references" v-show="promptMap[promptRef.prompt_id].display || debug" class="prompt" :class="[{'hidden':!promptMap[promptRef.prompt_id].display}]"/>
              </template>           -->
              <div style="margin-left: 30px; color: teal; font-weight: bold;" v-if="ui.completionHelper && question.display===true && question.valid===false">
                *<str index="questionSet.errors.incomplete"/>
              </div>

              <template v-if="logics.length>0 && question.canHide && debug">
                
                  <v-container>
                      <v-row>
                          <v-col>
                              Requirement: {{question.displayLogic.requirement}}
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col>Description</v-col>
                          <v-col>Rule</v-col>
                          <v-col>Input</v-col>
                      </v-row>

                      <template v-for="(displayRule, displayRuleIndex) in question.displayLogic.rules">
                          <template v-if="logicMap[displayRule.rule_id] && logicMap[displayRule.rule_id].report">
                              <template v-for="(rule, r) in logicMap[displayRule.rule_id].report.rules">
                              <v-row :key="'qid_'+question.id+'_rule_'+displayRuleIndex+'_'+r">
                                  <v-col class="pa-0">{{logicMap[displayRule.rule_id].description}}</v-col>
                                  <v-col class="pa-0"><span v-html="rule.helper"/></v-col>
                                  <v-col class="pa-0"><span v-if="rule.input.answer.value" v-html="rule.input.answer.value"/></v-col>
                              </v-row>
                              </template>
                          </template>

                          <v-row v-else :key="'qid_'+question.id+'_rule_'+displayRuleIndex">
                              <v-col>
                                  MISSING LOGIC {{displayRule}}
                              </v-col>
                          </v-row>
                      </template>
                  </v-container>
              </template>
            </div>
          </template>

          <div v-for="(prompt, prompt_id) in promptMap" :key="'prompt_id_'+prompt_id">
            <Prompt :prompt="prompt" @close_prompt="$emit('close_prompt',prompt)"/>
          </div>

        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn class="themed" :disabled="busy" @click="submitSurvey" id="_submitButton">
            <str index="buttons.submit"/>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    
    <v-snackbar v-model="ui.allowSubmit.display" :multi-line="true" :timeout="3000">
      <div style="text-align: center;">
        <str v-if="allowSubmit" index="questionSet.messages.ready_to_submit"/>
        <str v-else index="questionSet.errors.complete_required_questions"/>
        <br>
        <template v-if="allowSubmit">
            <!-- <v-btn small color="primary" style="margin: 25px;" @click="submitSurvey">Submit</v-btn> -->
            <!-- <v-btn small color="primary" style="margin: 25px;" v-if="!visibleSubmit" @click="survey().scrollToSubmit()">Go to submit</v-btn> -->
        </template>
      </div>
    </v-snackbar>
</div>
</template>

<script>
import Question from './Question'
import Prompt from './Prompt'
import functions from './functions'

let surveyFunctions = new function(){

    this.loadQuestions = function(key){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache');
        if(cache){
            cache = JSON.parse(cache);
            if(cache[key]){
                return cache[key]
            }
        }
        return false
    }

    this.scrollToQuestion = function(question){
      let elem = document.querySelector("[data-question-id='"+question.id+"']")
      elem.scrollIntoView({behavior:'smooth'})
      // window.scrollTo({top: elem.offsetTop, behavior:'smooth'})
    }

    this.scrollToSubmit = function(){
      let elem = document.querySelector('#_submitButton')
      elem.scrollIntoView({behavior:'smooth'})
        // window.scrollTo({top: elem.offsetTop, behavior:'smooth'})        
    }

    this.highlightQuestionON = function(question){
      document.querySelector("[data-question-id='"+question.id+"']").parentElement.classList.add('highlight')
    }

    this.highlightQuestionOFF = function(question){
      document.querySelector("[data-question-id='"+question.id+"']").parentElement.classList.remove('highlight')
    }

}

let timeTracker = function(component){
    let timer = null

    this.start = function(start_time=0){
        clearInterval(timer)
        component.time_spent = start_time
        timer = setInterval(()=>{
            if(component.$store.getters.appVisible){
                component.time_spent++
            }
        },1000)
    }

    this.stop = function(){
        clearInterval(timer)
        component.time_spent = 0
    }

    this.pause = function(){
        clearInterval(timer)
    }

    this.reset = function(){
        component.time_spent = 0
    }
}

let clone = function(object){
    return JSON.parse(JSON.stringify(object))
}

export default {
    name: "QuestionSet",
    props: {
        questionSetID: {
            type: Number,
            required: true
        },
        // questionSet: {
        //     type: Object,
        //     required: true
        // },
        requestDebug: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Question,
        Prompt,
        functions
    },
  created: function(){
      let questionSet = this.questionSet
      for(let item in questionSet){
        this[item] = questionSet[item]
      }
      // this.questions = questionSet.questions
      // this.logics = questionSet.logics
      // this.prompts = questionSet.prompts
      // this.references = questionSet.references
      // this.assets = questionSet.assets
      this.init()
    // let cache = this.survey().loadQuestions(this.questionSetID)

    // if(cache){
    //     this.questions = cache.questions
    //     this.logics = cache.logics
    //     this.prompts = cache.prompts
    //     this.init()
    // }else{
    //     console.error('No question set found: '+this.questionSetID)
    // }
  },
  data: function(){
    return {
      ready: false,
      busy: false,
      ui: {
        debug: false,
        questionNav: true,
        allowSubmit: {
          display: false
        },
        completionHelper: false,
        triggers: {}
      },
      time_spent: 0,
      questions: [],
      logics: null,
      prompts: null,
      references: null,
      assets: null,
      hub: {
        logicIndex: undefined,
        logs: []
      },
      logs: [],
      timers: {
        allowSubmit: null,
        displayLogic: null,
        reset: null,
        css: null
      },
      busyTimer: null,
      invalidQuestions: []
    }
  },
  methods: {
      consoleLogs: function(){
          console.table(this.hub.logs)
      },
    survey: function(){
      return surveyFunctions
    },
    init: function(){
      let self = this
      functions.initializeQuestions(self, self.questions, self.logics)
      self.timeTracker.reset()
      self.timeTracker.start()
    },
    cacheAnswers: function(){
      functions.cacheAnswers(this.$store.getters.userID , this.questionSetID, {answers: this.getForms(this.questionMap), time_spent: this.time_spent, logs: this.hub.logs})
    },
    loadAnswers: function(){
      functions.loadAnswers(this.$store.getters.userID , this.questionSetID, this)
    },
    getForms: function(questions){
      let output = {}
      for(let id in questions){
        let question = questions[id]
        output[question.id] = question.form
      }
      return output
    },
    displayHandler: function(question){
        let logRecord = {
            time: this.time_spent,
            form: question.form,
            qid: question.id
        }
        if(this.ready){
            this.hub.logs.push(clone(logRecord))
        }
      let self = this
      clearTimeout(this.timers.displayLogic)
      self.timers.displayLogic = setTimeout(function(){
        self.hub.logicIndex = functions.logicHandler({questionMap:self.questionMap, logicMap:self.logicMap}, self.logics)
        functions.displayHandler(question, self.hub.logicIndex, self.questionMap)
        functions.disableHandler(question, self.hub.logicIndex, self.questionMap)
        functions.promptDisplayHandler(question, self.hub.logicIndex, self.promptMap)
        self.collectInvalidQuestions()
        self.$forceUpdate()
      },100)
    },
    randomize: function(){
      let delay = 0
      let self = this
      for(let qid in self.ui.triggers){
        delay+=300
        let target = self.ui.triggers[qid]
        setTimeout(function(){
          target.randomize++
        },delay)
      }
    },
    reset: function(){
      let delay = 0
      let self = this
      self.ui.completionHelper = false
      self.ready = false
      for(let qid in self.ui.triggers){
        delay+=300
        let target = self.ui.triggers[qid]
        setTimeout(function(){
          clearTimeout(self.timers.reset)
          self.timers.reset = setTimeout(function(){
            self.ready = true
          },1000)
          target.reset++
        },delay)
      }
    },
    submitSurvey: function(){
      if(!this.allowSubmit){
        this.ui.completionHelper = true
        this.survey().scrollToQuestion(this.invalidQuestions[0])
        this.ui.allowSubmit.display = true   
      }else{
          let self = this
          clearTimeout(self.busyTimer)
          self.busy = true
          self.busyTimer = setTimeout(function(){
            self.busy = false
          },2000)
          this.$emit('submit',{
              questions: this.getForms(this.questions),
              hub: this.hub,
              logics: this.logics,
              prompts: this.prompts
          })
      }
    },
    visibleSubmit: function(){
        function isInViewport(element) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
        return isInViewport(document.getElementById('_submitButton'))
    },
    collectInvalidQuestions: function(){
      let output = []


      let questions = this.questions
      if(questions){
        for(let i=0; i<questions.length; i++){
          let question = questions[i]
          let invalid = (question.display===true && question.valid!=true)        
          if(invalid){
            output.push(question)
          }
        }
      }

      this.invalidQuestions = output
    },
  },
  computed: {
    questionSet: function(){
      return this.$attrs.value
    },
    debug: function(){
      // return this.$store.getters.debug || this.ui.debug
      return this.ui.debug
    },
    timeTracker: function(){
        let tool = new timeTracker(this)
        return tool
    },
    questionMap: function(){
      let questions = this.questions
      let output = {}

      if(questions){
        for(let i=0; i<questions.length; i++){
          let question = questions[i]
          output[question.id] = question
        }

      }
      return output
    },
    promptMap: function(){
        let prompts = this.prompts
        let output = {}
        for(let i=0; i<prompts.length; i++){
            let prompt = prompts[i]
            output[prompt.id] = prompt
        }

        return output
    },
    logicMap: function(){
      let logics = this.logics
      let output = {}

      for(let i=0; i<logics.length; i++){
        let logic = logics[i]
        output[logic.id] = logic
      }

      return output
    },
    assetMap: function(){
        let array = this.assets
        let output = {}
        
        if(array){
          for(let i=0; i<array.length; i++){
              let item = array[i]
              output[item.id] = item
          }
        }

        return output
    },
    completionHelper: function(){
      return {
        config: {
          offsetTop: document.querySelector('div.app-header').offsetHeight+'px',
          offsetRight: ((window.innerWidth - document.querySelector("main.v-main").offsetWidth) / 2)+150+'px'
        }
      }
    },
    // invalidQuestions: function(){
    //   console.log('checking invalid questions...')
    //   let output = []


    //   let questions = this.questions
    //   if(questions){
    //     for(let i=0; i<questions.length; i++){
    //       let question = questions[i]
    //       let invalid = (question.display===true && question.valid!=true)        
    //       if(invalid){
    //         output.push(question)
    //       }
    //     }
    //   }
    //   console.log('...done')

    //   return output
    // },
    allowSubmit: function(){
      let self = this
      let result = self.ready && self.invalidQuestions.length==0
      return result
      
    }
  },
  watch: {
    allowSubmit: function(){
      let self = this
      if(self.allowSubmit && self.ui.completionHelper){
        self.ui.allowSubmit.display = true
        self.ui.completionHelper = false
      }
      // self.ui.allowSubmit.display = true
      // if(!self.allowSubmit){
      //   self.ui.completionHelper = true
      // }else{
      //   self.ui.completionHelper = false
      // }
      self.$emit('allowSubmit',self.allowSubmit)
    },
    questions: {
        deep: true,
        handler: function(){
            let self = this
            clearTimeout(self.timers.css)
            if(self.ready){
              self.timers.css = setTimeout(function(){
                let containers = document.getElementsByClassName('question-container')
                for(let i=0; i<containers.length; i++){
                  let container = containers[i]
                  container.classList.remove('even')
                }
  
  
                containers = document.getElementsByClassName('question-container visible')
                for(let i=0; i<containers.length; i++){
                  let container = containers[i]
                  if(i%2==1){
                    container.classList.add('even')
                  }
                }              
              },200)
            }

            this.$emit('input',{
                questions: this.questions,
                hub: this.hub,
                logics: this.logics,
                prompts: this.prompts
            })
        }
    }
  }

}
</script>

<style scoped>
.debug-logic-summary thead th{
  border-bottom: 1px solid darkgrey;
}
.debug-logic-summary tbody tr:nth-child(odd){
  background-color: #dddddd;
}

.debug-logic-summary .true{
  font-weight: bold;
  color: green;
}

.debug-logic-summary .false{
  font-weight: bold;
  color: red;
}

.completion-helper{
  z-index: 1000;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 15px;
  background-color: red;
  margin: 3px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 8pt;
  cursor: pointer;
}
.completion-helper:hover{
  border-color: red;
}

.question-container{
  color: black;
  border: 1px solid transparent;
  border-radius: 3px;
  margin: 15px;
}

.question-container-incomplete{
  border-color: rgba(19, 242, 234, .4);
}

.hidden{
    background-color: lightgrey;
    border: 1px solid darkgrey;
    color: grey;    
}

.hasLogic{
  border: 1px solid purple;
}

/* .prompt{
  border: 1px solid teal;
  padding: 12px;
  border-radius: 5px;
  margin: 15px 0px 15px 0px;
} */

.highlight{
  border-color: red;
}
</style>

<style>
.question-body{
  /* background-color: whitesmoke;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px; */
}
.question-container .theme--light.v-list, .question-container .theme--light.v-sheet{
  background-color: transparent !important;
}
.question-container .theme--light.v-label{
  color: black !important;
}
.question-container.even{
  background-color: whitesmoke;
}
</style>